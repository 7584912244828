import {Route} from "react-router-dom";
import React from "react";


export function toRoute(routeElement, index): JSX.Element {
  if (routeElement.nestedRoutes) {
    return <Route path={routeElement.route} element={routeElement.element} key={index}>
      {routeElement.nestedRoutes.map((nestedRoute, idx) => (
        <Route path={nestedRoute.route} element={nestedRoute.element} key={idx}/>
      ))}
    </Route>
  }

  return <Route path={routeElement.route} element={routeElement.element} key={index}/>
}
